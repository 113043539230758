import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function LegalNotice() {
  return (
    <Layout>
      <Seo
        title="Legal Notice"
        description="The vendor of this internet presence is BitCompass One UG(haftungsbeschränkt)"
      />
      <div className="prose lg:prose-xl dark:prose-invert  mx-auto max-w-5xl px-4 flex flex-col justify-center">
        <h1>Legal Information</h1>
        <h3>Vendor</h3>
        <p>
          The vendor of this internet presence is BitCompass One
          UG(haftungsbeschränkt)
        </p>
        <h3>Contact Information</h3>
        <p className="grid">
          <span>
            <b>Address:</b> BitCompass One UG(haftungsbeschränkt)
          </span>
          <span>Feringastr. 6 85774 Unterföhring</span>
          <span>
            <b>Telephone:</b> +49 89 23047017
          </span>
          <span>
            <b>Web:</b> www.dennissimon.dev{" "}
          </span>
          <span>
            <b>E-Mail:</b> dennism.simon94@gmail.com
          </span>
        </p>
        <h3>Representative:</h3>
        <p>
          BitCompass One UG(haftungsbeschränkt) is legally represented by Dennis
          Simon.
        </p>
        <h3>Company Registration:</h3>
        <p>
          <b>Munich HRB 273250</b>
        </p>
        <h3>Person responsible for content</h3>
        <p>Dennis Simon</p>
      </div>
    </Layout>
  )
}
